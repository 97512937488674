<template>
  <div class="main">
    <div class="navBarArea" >
        <nav-bar  :nowSelectedPage=1 :scrolled="pageScrolled" ref="navbar"></nav-bar>
    </div>
    <img src="../assets/whoWeArePic1.webp" alt="" class="topBackground">
    <div class="whoWeAreWrap">
      <img src="../assets/whoWeArePic2.webp" alt="">
      <div class="textWrap">
        <div class="title">Who we are</div>
        <div class="brief">
            Jiyu Biopharm Co., Ltd. is a high technology expertise focusing on animal health industry, located in Nanjing National Agricultural Innovation Park.<br><br>
            The activity covers from R&D, registration, production, sales and marketing for animal health products, including medicines, nutritions and healthcare products.<br><br>
            Our team: Team member from our senior management holds more than 15 years’ experience in veterinary health industry, more than 50 innovative new veterinary drugs and first generic drug cases are operated by our team member.
        </div>
      </div>
    </div>
    <div class="achievementsWrap">
        <div class="achievementsTextWrap">
            <div class="title">Research and Development Achievements</div>
            <div class="brief">National high-tech enterprise technology certification<br>
            Technology certification for technology-based small and medium-sized enterprises
            </div>
        </div>
        <div class="achievementsPicsArea">
             <div class="grid">
                <div class="textWrap">
                    <div class="text1">8</div>
                    <div class="text2">New Drugs under<br>Application</div>
                </div>
                <div class="dash"></div>
                <div class="textWrap">
                    <div class="text1">18</div>
                    <div class="text2">Patents</div>
                </div>
                <div class="dash"></div>
                <div class="textWrap">
                    <div class="text1">21</div>
                    <div class="text2">Trademarks</div>
                </div>
             </div>
             <div class="swiperArea">
                <img ref="leftArrow" src="../assets/swiperArrowLeft.webp" alt="" class="arrow" @click="this.$refs.swiper.prev" @mouseenter.prevent="activeArrow(0)" @mouseleave="deactiveArrow(0)">
                <my-swiper ref="swiper"></my-swiper>
                <img ref="rightArrow" src="../assets/swiperArrowRight.webp" alt="" class="arrow" @click="this.$refs.swiper.next" @mouseenter.prevent="activeArrow(1)" @mouseleave="deactiveArrow(1)">
             </div>
            
        </div>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import MySwiper from "./MySwiper.vue"
import PageFooter from './PageFooter.vue';

export default {
    data() {
        return {
            pageScrolled: false,
            autoPlayer: null,
        }
        
    },
    computed: {
        // isScrolled() {
        //     return this.pageScrolled
        // }
    },
    components: {
        NavBar,
        MySwiper,
        PageFooter
    },
    mounted() {
        const that = this
        window.addEventListener('scroll', function() {
            // console.log("aaa:" + that.$refs.navbar.isScrolled)
            if (window.$(document).scrollTop() >= 5) {
                that.pageScrolled = true
                // console.log("window.$(document).scrollTop:"+window.$(document).scrollTop())
            }
            else {
                that.pageScrolled = false
            }
        })
        if (that.autoPlayer == null){
            // interval在当前组件销毁前一定要销毁，不然切到其他组件后setinterval还存在调用refs就会报错
            that.autoPlayer = setInterval(
                function() {
                    that.$refs.swiper.next()
                },
                3000,
            )
        }
        // this.$nextTick()
        
    },
    updated() {
        // const that = this
        // if (that.autoPlayer == null){
        //     that.autoPlayer = setInterval(
        //         function() {
        //             that.$refs.swiper.next()
        //         },
        //         2000,
        //     )
        // }
        
    },
    beforeUnmount() {
        const that = this
        clearInterval(that.autoPlayer)
    },
    methods: {
        activeArrow(index) {
            const that = this
            clearInterval(that.autoPlayer)
            if (index == 0) {
                this.$refs.leftArrow.src = require("../assets/swiperArrowLeftActive.webp")
            }
            else {
                this.$refs.rightArrow.src = require("../assets/swiperArrowRightActive.webp")
            }
        },

        deactiveArrow(index) {
            const that = this
            // interval在当前组件销毁前一定要销毁，不然切到其他组件后setinterval还存在调用refs就会报错
            that.autoPlayer = setInterval(
                function() {
                    that.$refs.swiper.next()
                },
                3000,
            )
            if (index == 0) {
                this.$refs.leftArrow.src = require("../assets/swiperArrowLeft.webp")
            }
            else {
                this.$refs.rightArrow.src = require("../assets/swiperArrowRight.webp")
            }
        }
    }
        

}
</script>

<style scoped lang="scss">
.main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.topBackground {
  width: 100%;
  height: auto;
}

.whoWeAreWrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 5% 12% 7% 13%;

  img {
    width: 52%;
    height: auto;
  }

  .textWrap {
    display: flex;
    flex-direction: column;
    margin-left: 5%;

    .title {
        font-size: 1.5rem;
        font-weight: 700;
        color: #1A1A1A;
    }

    .brief {
        margin-top: 10%;
        white-space: pre-line;
        font-size: 1rem;
        line-height: 1.8rem;
        color: #646464;
    }
  }
}

.achievementsWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .achievementsTextWrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            font-size: 1.5rem;
            font-weight: 700;
            color: #1A1A1A;
        }

        .brief {
            margin-top: 4rem;
            line-height: 2.2rem;
            color: #646464;
            text-align: center;
        }
    }
    .achievementsPicsArea{
        
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: url("../assets/whoWeArePic3.webp");
        background-size: 100% 105%;
        margin-top: 4rem;

        .grid {
            background-color: rgba($color: #F7F7F7, $alpha: 0.6);
            border-radius: 1rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 75%;
            height: 15rem;
            box-shadow: 0 0 1.6rem 0.8rem rgba($color: #000000, $alpha: 0.1);

            .textWrap {
                // margin: 1.5rem 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                flex-grow: 1;
                
                .text1 {
                    color: $mainColor;
                    font-size: 3.4rem;
                    font-weight: 700;
                }

                .text2 {
                    margin-top: 1rem;
                    color: #646464;
                    font-size: 1.3rem;
                    font-weight: 600;
                    text-align: center;
                }
            }

            .dash {
                width: 1px;
                height: 70%;
                background-color: #7F7F7F;
                // flex-grow: 1;
            }
        }

        .carouselArea {
            width: 100%;
        }

        .swiperArea {
            margin-top: 6rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            margin-bottom: 4rem;

            .arrow {
                margin: 0 8rem;
                height: 8rem;
                width: auto;
                cursor: pointer;
            }

        }
    }
}

.el-carousel__item h3 {
color: #475669;
opacity: 0.75;
line-height: 200px;
margin: 0;
text-align: center;
}

.el-carousel__item:nth-child(2n) {
background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
background-color: #d3dce6;
}

</style>