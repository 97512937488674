<template>
    <div class="main">
      <div class="navBarArea" >
          <nav-bar  :nowSelectedPage=2 :scrolled="pageScrolled" ref="navbar" @tabMenu="subMenuSwitchTab"></nav-bar>
      </div>
      <img src="../assets/productDetailPic1.webp" alt="" class="topBackground">
      <div class="headWrap">
          <div class="leftWrap">
              <div class="text">PRODUCTS</div>
              <div class="dash"></div>
          </div>
          <div class="pathWrap">
              <div class="path1">{{ this.nutritionList[selected].path1 }}</div>
              <div class="path2">{{ this.nutritionList[selected].path2 }}</div>
          </div>
      </div>
      <div class="detailWrap">
          <div class="title">{{ this.nutritionList[selected].title }}</div>
          <div class="mainSubject">About This Product</div>
          <div class="subject">Details</div>
          <div style="display: flex; flex-direction: column; margin-bottom: 2rem;in">
              <div v-for="item, index in nutritionList[selected].detailList" :key="index" class="unorderedList">
                <div class="dot"></div>
                <div class="text">{{ item }}</div>
              </div>
          </div>
          <div v-if="this.nutritionList[selected].detailMore != ''" class="content">{{ this.nutritionList[selected].detailMore }}</div>
          <div class="subject">Specifications</div>
          <img v-if="this.nutritionList[selected].specification != ''" :src="this.nutritionList[selected].specification" :style="`width: ${this.nutritionList[selected].specificationWidth}; height: auto; margin-top: 1rem; margin-bottom: 1rem;`" alt="">
          <div class="subject">Precautions</div>
          <div class="content">{{ this.nutritionList[selected].precaution }}</div>
          <div class="mainSubject">Ingredient information</div>
          <div class="subject">Ingredients</div>
          <div class="content">{{ this.nutritionList[selected].ingredient }}</div>
          <div v-if="this.nutritionList[selected].analysis != ''" class="subject">Guaranteed Analysis (per tablet)</div>
          <img v-if="this.nutritionList[selected].analysis != ''" :src="this.nutritionList[selected].analysis" :style="`width: ${this.nutritionList[selected].analysisWidth}; height: auto; margin-top: 1rem; margin-bottom: 1rem;`" alt="">
          <div class="subject">Instructions</div>
          <div class="content">{{ this.nutritionList[selected].instruction }}</div>
          <img v-if="this.nutritionList[selected].instructionGrid != ''" :src="this.nutritionList[selected].instructionGrid" :style="`width: ${this.nutritionList[selected].instructionGridWidth}; height: auto; margin-top: 1rem; margin-bottom: 1rem;`" alt="">
          <div class="buttonWrap" @click="goBack">
              <img src="../assets/productdetailIcon1.webp" alt="" class="icon">
              <div class="text">BACK TO OVERVIEW</div>
          </div>
      </div>
      <page-footer></page-footer>
    </div>
  </template>
  
  <script>
  import NavBar from './NavBar.vue';
  import PageFooter from './PageFooter.vue';
  
  export default {
      props: {
          attr: {
              type: Object
          }
      },
      components: {
          NavBar,
          PageFooter
      },
      data() {
          return {
              pageScrolled: false,
              selected: this.attr.product,
              nutritionList: [
                  {
                      id:0,
                      path1: "what we do > Products > Nutritions >",
                      path2: "Anti-allergy Tablets",
                      title: "Anti-allergy Tablets",
                      detailList: [
                        "Anti-allergy Tablets reduce the release of cat allergens and improve allergy symptoms.",
                        "Contains lgY (Yolk immunoglobulin), a biologically active antibody derived from egg yolk, to effectively neutralize Fel d1 and Fel d4, the main allergens in cats, and blocks allergens at source without altering the normal physiological function of cats.",
                        "Improves cat-induced sneezing, runny or stuffy nose, itchy, red or watery eyes, coughing or wheezing, rashes, redness or itching of the skin, etc.",
                      ],
                      detailMore: "More than 90% of cat allergy people have IgE reactions to Fel d1 and Fel d4, the allergens secreted by the salivary and sebaceous glands of cats, resulting in allergy. The IgY (Yolk immunoglobulin) active protein in Anti-allergy Tablets double reduce the allergens Fel d1 and Fel d4 in cat saliva and hair, improve the symptoms of cat hair allergy in people, and realize the freedom of sucking cats.",
                      specification: require("../assets/nutritions/specification/0.webp"),
                      specificationWidth: "30%",
                      precaution: "For cats use only. Keep out of reach of children.",
                      ingredient: "Custard powder, Vitamin B1, Vitamin B12.",
                      analysis: require("../assets/nutritions/analysis/0.webp"),
                      analysisWidth: "30%",
                      instruction: "Store in a cool, dry area out of direct sunlight.\nAdminister tablet directly to cats or give with pet's food.",
                      instructionGrid: require("../assets/nutritions/instruction/0.webp"),
                      instructionGridWidth: "32%",
                  },
                  {
                      id:1,
                      path1: "what we do > Products > Nutritions >",
                      path2: "Anti-Diarrheal Paste",
                      title: "Anti-Diarrheal Paste",
                      detailList: [
                        "Anti-Diarrheal Paste helps regulate intestinal function and protect the gastrointestinal mucosa.",
                        "Contains elm bark extract, a natural ingredient, to quickly relieve symptoms of diarrhea, inflammatory bowel disease and gastritis, help repair and strengthen the mucosal barrier of the gastrointestinal tract and gently stop vomiting.",
                        "Contains prebiotics (FOS&MOS) to help the colonization and growth of intestinal probiotics and balance intestinal flora.",
                        "Improves the symptoms of watery, mushy, soft, watery, mucous membrane and jelly stools, and relieves intestinal discomfort.",
                        "For dogs and cats with various intestinal problems, nutritional supplementation and soothing conditioning.",
                      ],
                      detailMore: "",
                      specification: require("../assets/nutritions/specification/1.webp"),
                      specificationWidth: "34%",
                      precaution: "For dogs and cats use only. Keep out of reach of children. This product is not a substitute for medication, if you do not see improvement after 2 days of use, please seek the help of your veterinarian.",
                      ingredient: "Elm bark extract (4%), Fructooligosaccharide (FOS, 10%), mannan oligosaccharide (MOS, 3%), montmorillonite (9%), glutamine, phosphatidylinositol, malic acid, citrate, sodium citrate, guar gum, sodium carboxymethylcellulose, ɛ-polylysine hydrochloride",
                      analysis: "",
                      analysisWidth: "30%",
                      instruction: "Store in a cool, dry area out of direct sunlight.\nFeeding on an empty stomach. Adjust the feeder scale, push out the paste and feed directly.",
                      instructionGrid: require("../assets/nutritions/instruction/1.webp"),
                      instructionGridWidth: "30%",
                  },
                  {
                      id:2,
                      path1: "what we do > Products > Nutritions >",
                      path2: "Joint Support Tablets",
                      title: "Joint Support Tablets",
                      detailList: [
                        "Joint Support Tablets help care for your pet's joints and cartilage.",
                        "Contains glucosamine, chondroitin, turmeric extract, sodium hyaluronate, MSM and multiple mineral elements to support healthy cartilage and your pet’s joint health.",
                        "Glucosamine and Chondroitin Sulfate are a top 5:4 ratio, aiding in the repair and rebuilding of cartilage matrix, lubricating joints and relieving joint discomfort.",
                        "Reduces joint inflammation and pain and improves joint function.",
                        "Multiple minerals help strengthen bones, promote bone growth and enhance joint stability.",
                      ],
                      detailMore: "",
                      specification: require("../assets/nutritions/specification/2.webp"),
                      specificationWidth: "34%",
                      precaution: "For dogs and cats use only. Keep out of reach of children.",
                      ingredient: "Glucosamine hydrochloride, chondroitin sulfate sodium, dimethyl sulfoxide (MSM), sodium hyaluronate, turmeric extract, DHA, calcium biphosphate, manganese sulfate, copper (II) sulfate, zinc oxide, ferrous sulfate, magnesium oxide, vitamin C, Vitamin A acetate, vitamin B1, vitamin B2, vitamin B6, vitamin B12, D-calcium pantothenate, folic acid, niacinamide, D-biotin, dl-alpha-tocopheryl acetate.",
                      analysis: require("../assets/nutritions/analysis/2.webp"),
                      analysisWidth: "34%",
                      instruction: "Store in a cool, dry area out of direct sunlight.\nTake (tablets) with food. Take more than 1 tablet per day, please feed separately in the morning and evening.",
                      instructionGrid: require("../assets/nutritions/instruction/2.webp"),
                      instructionGridWidth: "42%",
                  },
                  {
                      id:3,
                      path1: "what we do > Products > Nutritions >",
                      path2: "Urinary Support Tablets",
                      title: "Urinary Support Tablets",
                      detailList: [
                        "Urinary Support Tablets support your pet’s normal urinary function.",
                        "Contains oligochitosan, cranberry extract, corn silk, green tea crude extract, sodium hyaluronate, L-tryptophan and raspberry to support your paw’s urinary tract health.",
                        "Helps conserve bladder mucosa and promotes smooth urination.",
                        "Helps prevent bad bacteria from attaching to the lining of your pet’s bladder wall and promotes the elimination of harmful substances from the urinary tract with urine.",
                        "Tryptophan & Theanine can soothe emotions and reduce the incidence of urinary tract disorders due to stress.",
                      ],
                      detailMore: "",
                      specification: require("../assets/nutritions/specification/3.webp"),
                      specificationWidth: "32%",
                      precaution: "For dogs and cats use only. Keep out of reach of children. It is recommended not to feed below the guided dosage and to increase the daily water intake of your beloved pet. In case of accidental overdose, contact a health professional immediately.",
                      ingredient: "Oligochitosan, cranberry extract, corn silk, green tea crude extract (theanine source), sodium hyaluronate, L-tryptophan, raspberry, calcium bicarbonate.",
                      analysis: require("../assets/nutritions/analysis/3.webp"),
                      analysisWidth: "32%",
                      instruction: "Store in a cool, dry area out of direct sunlight.\nTake (tablets) with food. Take more than 1 tablet per day, please feed separately in the morning and evening.",
                      instructionGrid: require("../assets/nutritions/instruction/3.webp"),
                      instructionGridWidth: "38%",
                  },
                  
              ]
          }
      },
      methods: {
          goBack() {
            let nowURL = window.location.href
            nowURL = nowURL.slice(0, nowURL.indexOf("#"))
            window.location.assign(nowURL + "#WhatWeDo0")
          }
      },
      mounted() {
          const that = this
          window.addEventListener('scroll', function() {
              // console.log("aaa:" + that.$refs.navbar.isScrolled)
              if (window.$(document).scrollTop() >= 5) {
                  that.pageScrolled = true
                  // console.log("window.$(document).scrollTop:"+window.$(document).scrollTop())
              }
              else {
                  that.pageScrolled = false
              }
          })
      },
  
  }
  </script>
  
  <style scoped lang="scss">
  .main {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
  }
  
  .topBackground {
    width: 100%;
    height: auto;
  }
  
  .headWrap {
      margin-top: 3rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 76%;
      border-bottom: 1px #D8D8D8 solid;
      padding-bottom: 0.4rem;
  
      .leftWrap {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          .text {
              color: #2C2B2B;
              font-size: 1.3rem;
              font-weight: 600;
          }
  
          .dash {
              width: 100%;
              background-color: $mainColor;
              height: 0.2rem;
              margin-top: 0.4rem;
          }
      }
  
      .pathWrap {
          display: flex;
          flex-direction: row;
  
          .path1 {
              color: #7F7F7F;
          }
  
          .path2 {
              margin-left: 0.3rem;
              color: $mainColor;
          }
      }
  }
  
  .detailWrap {
      margin-top: 3rem;
      width: 76%;
      display: flex;
      flex-direction: column;

      .unorderedList {
        display: flex;
        flex-direction: row;
        // align-items: center;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;

        .dot {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: $mainColor;
            margin-right: 1rem;
            margin-top: 0.5rem;
            flex-shrink: 0;
        }

        .text {
            color: #646465;
            white-space: pre-line;
            font-weight: 400;
            line-height: 1.8rem;
        }
      }
  
      .title {
          font-size: 2rem;
          color: $mainColor;
          font-weight: 600;
        //   margin-bottom: 2rem;
      }

      .mainSubject {
        font-size: 1.6rem;
        font-weight: 600;
        color: #1A1A1A;
        margin: 3.3rem 0 0 0;
      }
  
      .subject {
          font-size: 1.1rem;
          font-weight: 600;
          color: #1A1A1A;
          margin: 1.3rem 0 0.5rem 0;
      }
  
      .content {
          color: #646465;
          white-space: pre-line;
          line-height: 1.8rem;
          font-weight: 400;
      }
  
      .buttonWrap {
          width: 100%;
          height: 4rem;
          background-color: #EEEEEE;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 4rem;
          margin-bottom: 7rem;
          cursor: pointer;
  
          .icon {
              width: 0.6rem;
              margin-right: 3rem;
          }
  
          .text {
              font-size: 1.1rem;
              color: #2C2B2B;
          }
      }
  }
  
  </style>