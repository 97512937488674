<template>
    <div class="footer">
        Copyright © {{year}}-{{year}} Jiyu Biopharm Co., Ltd, All Rights Reserved.
    </div>
</template>

<script>
export default {
    computed: {
        year() {
            var date = new Date
            return date.getFullYear()
        }
    }
}
</script>

<style scoped lang="scss">
.footer {
    width: 100%;
    height: 3.5rem;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    color: #FFFFFF;
    text-align: center;
    line-height: 3.5rem;
}
    
</style>