<template >
    
    <div class="main">
        <div class="navBarArea">
            <nav-bar :scrolled="false" :nowSelectedPage=0></nav-bar>
        </div>
        <div class="carouselArea">
            <el-carousel trigger="click" 
            :autoplay="true" 
            :interval=6000
            :height="swiperHeight" 
            class="carousel" 
            ref="swiper" 
            :pause-on-hover="false"
            :motion-blur="true"
            arrow="never"
            @change="changePic">
                <el-carousel-item v-for="item, index in picList" :key="`homePic-${index}`" class="item">
                    <img :src="item" alt="" style="object-fit: cover; width: 100%; height: 100%;">
                </el-carousel-item>
            </el-carousel>
            <div class="textArea">
                <img src="../assets/homeSlogan.webp" alt="" class="slogan">
                <div class="brief">
                    {{briefList[selectedBriefIndex]}}
                </div>
            </div>
            <page-footer></page-footer>
        </div>
        <!-- <div class="test" @mouseenter="setMask" @mouseleave="setMaskDown">
            <img src="../assets/homePic1.webp" alt="" style="object-fit: cover; width: 100%; height: 100%;">
            <div class="mask"></div>
        </div> -->
    </div>
    
    
</template>

<script>
import NavBar from './NavBar.vue';
import PageFooter from "./PageFooter.vue";
import {ElCarousel, ElCarouselItem} from 'element-plus';



export default {
    props: {
        attr: {}
    },
    components: {
        NavBar,
        ElCarousel,
        ElCarouselItem,
        PageFooter
    },

    data() {
        return {
            picList: [
              require("../assets/homePic1.webp"),
              require("../assets/homePic2.webp"),
              require("../assets/homePic3.webp"),
            ],
            screenSize: [0, 0],
            swiperHeight: "100vh",
            selectedBriefIndex: 0,
            briefList: [
                "We are dedicated to improving animal healthcare and providing premium products that enhance the well-being of animals everywhere. ",
                "Our passion for animals drives us to deliver innovative solutions globally, ensuring that all animals receive the care they deserve, no matter where they are. ",
                "We envision a future where every animal has access to the highest quality products, ensuring a healthier, happier life for all species. "
            ]
        }
    },
    computed: {

    },
    mounted () {
        // const that = this
        window.addEventListener("resize", function() {
            this.screenSize = [document.body.clientWidth, document.body.clientHeight]
            // console.log(this.screenSize)
        })
        
    },
    methods: {
        changePic(next) {
            // console.log([prev, next])
            
            window.$(".textArea").css("opacity", "0")
            this.selectedBriefIndex = next
            
            setTimeout(
                function() {
                    this.$(".textArea").css("opacity", "1")
                },
                200
            )
        },
        // setMask() {
        //     window.$(".mask").css("height", "100%")
        // },
        // setMaskDown() {
        //     window.$(".mask").css("height", "0")
        // }
    }

}
</script >

<style scoped lang="scss">
// .main {
//     width: 100%;
//     height: auto;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// }

// .navBarArea{
//     width: 100%;
//     height: $navBarHeight;
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 999;
// }

// .el-carousel__container {
//     height: 100% !important;
// }

.carouselArea{
    width: 100%;
    height: 100%;
    position: relative;
}


// :deep(.el-carousel) {
//     --el-carousel-arrow-font-size: 12px;
//     --el-carousel-arrow-size: 36px;
//     --el-carousel-arrow-background: rgba(31,45,61,0.11);
//     --el-carousel-arrow-hover-background: rgba(31,45,61,0.23);
//     --el-carousel-indicator-width: 14rem;
//     --el-carousel-indicator-height: 8px;
//     --el-carousel-indicator-padding-horizontal: 4px;
//     --el-carousel-indicator-padding-vertical: 12px;
//     --el-carousel-indicator-out-color: var(--el-border-color-hover);
//     position: relative;
// }

:deep(.el-carousel__button) {
    height: 0.8vh;
    width: 22.5vw;
    border-radius: 0.4vh;
    opacity: 1;
}

:deep(.el-carousel__indicator.is-active button) {
    background-color: $mainColor;
}

:deep(.el-carousel__indicators--horizontal) {
    margin-bottom: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70vw;
}

.textArea {
    position: absolute;
    z-index: 10;
    left: 15%;
    top: 22%;
    transition: opacity 0.2s ease;

    img {
        width: 32vw;
        height: auto;
    }

    .brief {
        width: 32vw;
        font-size: 1.3rem;
        // letter-spacing: 0.1rem;
        line-height: 2.4rem;
        color: #FFFFFF;
    }
}

// .test {
//     width: 20rem;
//     height: 20rem;
//     // transform: skew(-20deg,0);
//     clip-path: polygon(20% 0, 100% 0, 80% 100%, 0 100%);
//     position: relative;
//     // background-color: #000000;

//     img {
//         transform: skew(0,0);
//     }

//     .mask {
//         width: 100%;
//         height: 0;
//         background-color: rgba(0, 0, 0, 0.3);
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         z-index: 13;
//         transition: all 0.5s ease;

        
//     }
// }

</style>