<template>
    <div :class="!isScrolled ? 'navbarWrap' : 'navbarWrap navbarWrapScrolled'">
        <div class="navBarLogo">
            <img v-if="this.isScrolled" src="../../static/navbarLogo1.png" alt="">
            <img v-else src="../../static/navbarLogo2.png" alt="">
        </div>
        <div class="menuListWrap">
            <div v-for="item in menuList" :key="item.id" class="menuItem" @mouseenter="menuHover(item.id)" @mouseleave="menuOut(item.id)" @click="jumpToPage(item.jumpTo, item.id)">
                <div :class="!isScrolled ? 'text' : 'text textScrolled'">{{item.value}}</div>
                <div v-if="item.selected" class="selectedDash"></div>
                <Transition name="subMenuTransition">
                    <div v-if="item.children.length > 0 && item.hovered" class="menuSubItemWrap">
                        <div v-for="subItem, index in item.children" :key="subItem.id" class="subItem" @click.stop="jumpToPage(subItem.jumpTo, item.id)">
                            <div class="text">{{subItem.value}}</div>
                            <div v-if="index != item.children.length - 1" class="splitDash"></div>
                        </div>
                    </div>
                </Transition>
                
            </div>
            
        </div>
    </div>
</template>

<script>
import uniqueId from "lodash.uniqueid"

export default {
    props: {
        scrolled: {
            default: false,
            type: Boolean
        },
        nowSelectedPage: {
            required: true,
            default: 0,
            type: Number
        }
    },
    computed: {
        isScrolled() {
            return this.scrolled
        }
    },
    data() {
        return {
            nowPage: this.nowSelectedPage,
            // isScrolled: this.scrolled,
            menuList: [
                {
                    id: uniqueId("menu-"),
                    value: "HOME",
                    selected: false,
                    hovered: false,
                    jumpTo: "",
                    children: []
                },
                {
                    id: uniqueId("menu-"),
                    value: "WHO WE ARE",
                    selected: false,
                    hovered: false,
                    jumpTo: "WhoWeAre",
                    children: []
                },
                {
                    id: uniqueId("menu-"),
                    value: "WHAT WE DO",
                    selected: false,
                    hovered: false,
                    jumpTo: "WhatWeDo0",
                    children: [
                        {
                            id: uniqueId("subMenu-"),
                            value: "PRODUCTS",
                            jumpTo: "WhatWeDo0",
                        },
                        {
                            id: uniqueId("subMenu-"),
                            value: "SERVICE",
                            jumpTo: "WhatWeDo1",
                        },
                    ]
                },
                {
                    id: uniqueId("menu-"),
                    value: "OUR ACTIVITIES",
                    selected: false,
                    hovered: false,
                    jumpTo: "OurActivities0",
                    children: [
                        {
                            id: uniqueId("subMenu-"),
                            value: "R&D AND\nPRODUCTION",
                            jumpTo: "OurActivities0",
                        },
                        {
                            id: uniqueId("subMenu-"),
                            value: "DOMESTIC MARKETING\nAND SALES",
                            jumpTo: "OurActivities1",
                        },
                        {
                            id: uniqueId("subMenu-"),
                            value: "INTERNATIONAL\nCOOPERATIONS",
                            jumpTo: "OurActivities2",
                        },
                    ]
                },
                {
                    id: uniqueId("menu-"),
                    selected: false,
                    value: "CONTACT",
                    jumpTo: "ContactUs",
                    children: []
                },
            ]
        }
    },

    methods: {
        menuHover(id) {
            const nowItem = this.menuList.find((item) => item.id == id)
            nowItem.hovered = true
        },
        menuOut(id) {
            const nowItem = this.menuList.find((item) => item.id == id)
            nowItem.hovered = false
        },
        jumpToPage(pageName, id) {
            this.menuList[this.nowPage].selected = false
            var selectedMenuIndex = this.menuList.findIndex((item) => item.id == id)
            this.nowPage = selectedMenuIndex
            this.menuList[selectedMenuIndex].selected = true
            let nowURL = window.location.href
            nowURL = nowURL.slice(0, nowURL.indexOf("#"))
            window.location.assign(nowURL + "#" + pageName)
            this.$emit("tabMenu", pageName)
        }
    },
    mounted() {
        this.menuList[this.nowPage].selected = true
    }
        
}
</script>

<style scoped lang="scss">

.navbarWrap {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 15%;

    .navBarLogo {
        height: 50%;
        width: auto;

        img {
            height: 100%;
            width: auto;
        }
    }

    .menuListWrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // width: 64%;
    height: 100%;

        .menuItem{
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            padding: 0 2.8rem;
            &:hover {
                cursor: pointer;
                .text {
                    color: $mainColor;
                    
                }
                .textScrolled {
                    color: $mainColor;
                }
            }

            .subMenuTransition-enter-active {
                transition: opacity 0.3s ease-in-out;
            }
            .subMenuTransition-leave-active
            .subMenuTransition-enter-from {
                opacity: 0;
            }
            .subMenuTransition-enter-from {
                opacity: 1;
            }
            .subMenuTransition-leave-from {
                opacity: 1;
            }
            .subMenuTransition-enter-from {
                opacity: 0;
            }


            .text {
                color: #FFFFFF;
                font-size: 1rem;
                white-space: nowrap;
            }

            .textScrolled {
                color: #2C2B2B;
                
            }

            .selectedDash {
                width: 100%;
                height: 0.3rem;
                position: absolute;
                bottom: 0.3rem;
                background-color: $mainColor;
                border-radius: 0.15rem;
            }

            .menuSubItemWrap {
                position: absolute;
                top: $navBarHeight;
                width: 100%;
                background-color: $mainColor;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                .subItem {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: auto;
                    &:hover {
                        .text {
                            color: #2C2B2B;
                        }
                    }
                    .text {
                        color: #FFFFFF;
                        padding: 12% 0;
                        text-align: center;
                        white-space: pre-line;
                    }
                    .splitDash {
                        width: 80%;
                        height: 1px;
                        background-color: #FFFFFF;
                    }
                }
            }
        }
    }
}

.navbarWrapScrolled {
    background-color: #FFFFFF;
}



</style>