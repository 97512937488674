<template>
    <div class="main">
        <div class="navBarArea" >
            <nav-bar  :nowSelectedPage=3 :scrolled="pageScrolled" ref="navbar" @tabMenu="subMenuSwitchTab"></nav-bar>
        </div>
        <img src="../assets/ourActivitiesPic1.webp" alt="" class="topBackground">
        <div class="tabWrap">
            <div class="tab" @click="switchTab(0)">
                <div class="text">R&D and Production</div>
                <div v-if="selected == 0" class="selectedDash"></div>
            </div>
            <div class="split"></div>
            <div class="tab" @click="switchTab(1)">
                <div class="text">Domestic marketing and Sales</div>
                <div v-if="selected == 1" class="selectedDash"></div>
            </div>
            <div class="split"></div>
            <div class="tab" @click="switchTab(2)">
                <div class="text">International cooperations</div>
                <div v-if="selected == 2" class="selectedDash"></div>
            </div>
        </div>
        <div class="infoArea">
            <img :src="infoList[selected].pic" alt="" class="pic">
            <div class="textWrap">
                <div class="title">{{infoList[selected].title}}</div>
                <div class="brief">{{infoList[selected].brief}}</div>
            </div>
        </div>
        <page-footer></page-footer>
    </div>
</template>

<script>
import NavBar from './NavBar.vue';
import PageFooter from './PageFooter.vue';

export default {
    props: {
        attr: {
            type: Object
        }
    },
    computed: {
        
    },
    data() {
        return {
            selected: this.attr.tabIndex,
            pageScrolled: false,
            infoList: [
                {
                    pic: require("../assets/ourActivitiesPic2.webp"),
                    title: "R&D and Production",
                    brief: `Tablet\n
                            Chewable tablet\n
                            Bolus\n
                            Topical solution\n
                            Topical ointment\n
                            Oral solution\n
                            Collar
                            Paste`
                },
                {
                    pic: require("../assets/ourActivitiesPic3.webp"),
                    title: "Marketing and Sales",
                    brief: `Pre-launching market analysis\n
                            Product market research and competitors-analysis\n
                            Product trial evaluation\n
                            Product training\n
                            Product marketing activity planning\n
                            Promotion marketing material\n
                            Qualified logistic partners for both domestic & international deliveries\n
                            Integrated warehousing\n
                            Social welfare`
                },
                {
                    pic: require("../assets/ourActivitiesPic4.webp"),
                    title: "International cooperations",
                    brief: `Contract R&D and manufacturing: Various dosage forms\n
                            Regulatory support: Regulatory dossier preparation and application\n
                            Marketing and distribution: Sales and marketing across China`
                },
            ]
        }
    },
    components: {
        NavBar,
        PageFooter
    },
    methods: {
        switchTab(index) {
            this.selected = index
        },
        subMenuSwitchTab(name) {
            if (name == "OurActivities0" || name == "OurActivities1" || name == "OurActivities2") {
                this.selected = Number(name.slice(-1))
            }
        }
    },
    mounted() {
        const that = this
        window.addEventListener('scroll', function() {
            // console.log("aaa:" + that.$refs.navbar.isScrolled)
            if (window.$(document).scrollTop() >= 5) {
                that.pageScrolled = true
                // console.log("window.$(document).scrollTop:"+window.$(document).scrollTop())
            }
            else {
                that.pageScrolled = false
            }
        })
    },

}
</script>

<style scoped lang="scss">
.main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.topBackground {
  width: 100%;
  height: auto;
}

.tabWrap {
    width: 100%;
    height: 4rem;
    background-color: rgba($color: #7F7F7F, $alpha: 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12%;

    .split {
        width: 1px;
        height: 40%;
        background-color: #2C2B2B;
        margin-right: 4rem;
    }

    .tab {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        margin-right: 4rem;
        cursor: pointer;

        .text {
            font-size: 1.2rem;
            font-weight: 500;
            color: #2C2B2B;
        }

        .selectedDash {
            height: 0.4rem;
            width: 100%;
            background-color: $mainColor;
            position: absolute;
            bottom: 0.3rem;
            border-radius: 0.3rem;
        }

    }
}

.infoArea {
    display: flex;
    flex-direction: row;
    align-items: start;
    padding-left: 12%;
    margin-top: 5rem;
    // justify-content: center;
    width: 100%;
    margin-bottom: 9rem;

    .pic {
        width: 46%;
        height: auto;
    }

    .textWrap {
        display: flex;
        flex-direction: column;
        margin-top: 3rem;
        margin-left: 5rem;
        
        .title {
            font-size: 2rem;
            font-weight: 600;
            color: #1A1A1A;
        }

        .brief {
            margin-top: 4rem;
            font-size: 1rem;
            font-weight: 500;
            white-space: pre-line;
            color: #646465;
        }
    }
    
}

</style>