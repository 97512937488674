<template>
  <div class="contentWrap">
    <div class="path">{{ path }}</div>
    <div class="productsWrap">
        <div class="itemWrap" v-for="item, index in productList" :key="item.id" :style="(index + 1) % column == 0 ? itemWrapStyleEnd : itemWrapStyle">
            <div class="title"><div>{{ item.title }}</div></div>
            <div class="itemContentWrap">
                <div class="textWrap">
                    <div class="brief">{{ item.brief }}</div>
                    <div class="category">{{ item.category }}</div>
                </div>
                <div class="button" @click="toPage(item.toPage)">
                    <div class="text">Go to detailpage</div>
                    <img class="icon" src="../assets/whatWeDoIcon1.webp" alt="">
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        path: {
            required: true,
            type: String
        },
        productList: {
            required: true,
            type: Array
        },
        column: {
            required: true,
            type: Number
        }
    },
    computed: {
        itemWrapStyle() {
            return {
                "width": `${this.column == 3 ? 31 : 22.75}%`,
                "display": "flex",
                "flex-direction": "column",
                "align-items": "center",
                "margin-bottom": "4rem",
                "margin-right": `${this.column == 3 ? 3.5 : 3}%`
            }
        },
        itemWrapStyleEnd() {
            return {
                "width": `${this.column == 3 ? 31 : 22}%`,
                "display": "flex",
                "flex-direction": "column",
                "align-items": "center",
                "margin-bottom": "4rem",
            }
        }
    },
    methods: {
        toPage(pageName) {
            let nowURL = window.location.href
            nowURL = nowURL.slice(0, nowURL.indexOf("#"))
            window.location.assign(nowURL + "#" +pageName)
        }
    }
        
}
</script>

<style scoped lang="scss">
.contentWrap {
    width: 100%;
    display: flex;
    flex-direction: column;

    .path {
        color: #2C2B2B;
        font-weight: 600;
        margin-bottom: 2rem;
    }

    .productsWrap {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // align-content: stretch;

        .itemWrap {
            // width: 17vw;
            // display: flex;
            // flex-direction: column;
            // align-items: center;
            
            .title {
                background-color: $mainColor;
                // font-size: 1.1rem;
                color: #FFFFFF;
                // padding: 0.5rem 1.2rem;
                height: 5rem;
                align-items: center;
                justify-content: center;
                display: flex;
                flex-direction: column;
                font-weight: 600;
                white-space: pre-line;
                width: 100%;
                text-align: center;
            }

            .itemContentWrap {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 1rem 1rem;
                background-color: #FFFFFF;
                flex-grow: 1;
                justify-content: space-between;

                .textWrap {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-bottom: 1rem;
                    min-height: 8rem;


                    .brief {
                        white-space: pre-line;
                        color: #646465;
                        font-weight: 500;
                        margin-bottom: 1rem;
                    }

                    .category {
                        white-space: pre-line;
                        color: #646465;
                        font-weight: 200;
                    }
                }

                .button {
                    width: 100%;
                    background-color: #EEEEEE;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    padding: 1rem 0;
                    cursor: pointer;

                    .text {
                        margin-right: 0.5rem;
                    }

                    .icon {
                        height: 0.8rem;
                        width: auto;
                    }

                }

                
            }
        }
    }

}
</style>