<template>
  <div class="main">
    <div class="navBarArea" >
        <nav-bar  :nowSelectedPage=4 :scrolled="pageScrolled" ref="navbar"></nav-bar>
    </div>
    <img src="../assets/contactPic1.webp" alt="" class="topBackground">
    <div class="introductionWrap">
        <img src="../assets/contactPic2.webp" alt="" class="map">
        <div class="textWrap">
            <div class="name">Jiyu Biopharm Co., Ltd.</div>
            <div class="wrap">
                <img src="../assets/contactIcon1.webp" alt="" class="icon">
                <div class="text">Building A1, Nanjing National Agricultural Innovation Park, No. 10, Xingzhi<br>Road, Pukou District, Nanjing City, Jiangsu Province, P.R. China 211899</div>
            </div>
            <div class="wrap">
                <img src="../assets/contactIcon2.webp" alt="" class="icon">
                <div class="text">info@jiyupharm.com</div>
            </div>
            <div class="wrap">
                <img src="../assets/contactIcon3.webp" alt="" class="icon">
                <div class="text">0086-25-69529001</div>
            </div>
        </div>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import PageFooter from './PageFooter.vue';

export default {

    data() {
        return {
            pageScrolled: false
        }
        
    },

    components: {
        NavBar,
        PageFooter
    },

    mounted() {
        const that = this
        window.addEventListener('scroll', function() {
            // console.log("aaa:" + that.$refs.navbar.isScrolled)
            if (window.$(document).scrollTop() >= 5) {
                that.pageScrolled = true
                // console.log("window.$(document).scrollTop:"+window.$(document).scrollTop())
            }
            else {
                that.pageScrolled = false
            }
        })
    }

}
</script>

<style scoped lang="scss" >
.main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.topBackground {
  width: 100%;
  height: auto;
}

.introductionWrap {
    margin-top: 5rem;
    margin-bottom: 8rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .map {
        width: 35%;
        height: auto;
    }

    .textWrap {
        display: flex;
        flex-direction: column;
        margin-left: 7rem;

        .name {
            font-size: 1.5rem;
            font-weight: 700;
            color: #3D3D3D;
        }

        .wrap {
            margin-top: 2rem;
            display: flex;
            flex-direction: row;
            align-items: center;

            .icon {
                width: 1.5rem;
                height: auto;
            }

            .text {
                margin-left: 1rem;
                font-size: 0.9rem;
                color: #3D3D3D;
                white-space: pre-line;
            }

        }
    }
}

</style>