<template>
  <div>
    <transition name="fade">
      <div class="swiper">
        <div @click="chooseItem(item,index)" v-for="(item, index) in imgs" :style="config5[index]" :key="item.cover">
          <img :src="item.cover" style="width: 100%; height: 100%; object-fit: scale-down;">
        </div>
      </div>
    </transition>
    <!-- <h1 @click="prev">上一个</h1>
    <h1 @click="next">下一个</h1>
    <h1>当前：{{ centerInfo.id }}</h1> -->
    </div>
  </template>
  <script>
  export default {
      name: 'zt',
      data() {
          return {
              loading: true,
              currentIndex: 3, //当前中间imgs数组中index
              centerInfo: '', // 当前中间信息
              startX: '',
              endX: '',
              imgs: [
                  {
                      id: '莱因哈特1',
                      index: 0,
                      cover:
                          require("../assets/certificate/certificate1.webp")
                  },
                  {
                      id: '安娜2',
                      index: 1,
                      cover:
                      require("../assets/certificate/certificate2.webp")
                  },
                  {
                      id: '卢西奥3',
                      index: 2,
                      cover:
                      require("../assets/certificate/certificate3.webp")
                  },
                  {
                      id: 'DVA4',
                      index: 3,
                      cover:
                      require("../assets/certificate/certificate4.webp")
                  },
                  {
                      id: '莫伊拉5',
                      index: 4,
                      cover:
                      require("../assets/certificate/certificate5.webp")
                  },
                  {
                      id: '裂空6',
                      index: 5,
                      cover:
                      require("../assets/certificate/certificate6.webp")
                  },
                  {
                      id: '麦克雷7',
                      index: 6,
                      cover:
                      require("../assets/certificate/certificate8.webp")
                  },
                  
              ],
              previous: 0,
              config5: [
                  {
                      id: '-A',
                      position: 'absolute',
                      width: '22%',
                      height: '72%',
                      top: '19.2%',
                      left: '-22%',
                      opacity: 0,
                      zIndex: 0,
                      transition: '.4s'
                  },
                  {
                      id: 'A',
                      position: 'absolute',
                      width: '22%',
                      height: '72%',
                      top: '19.2%',
                      left: '0%',
                      opacity: 1,
                      zIndex: 1,
                      transition: '.4s'
                  },
                  {
                      id: 'B',
                      position: 'absolute',
                      width: '28%',
                      height: '82%',
                      top: '14%',
                      left: '13%',
                      opacity: 1,
                      zIndex: 2,
                      transition: '.4s'
                  },
                  {
                      id: 'center',
                      position: 'absolute',
                      width: '45%',
                      height: '100%',
                      top: '0px',
                      left: '50%',
                      marginLeft: '-22.5%',
                      opacity: 1,
                      zIndex: 4,
                      transition: '.4s'
                  },
                  {
                      id: 'D',
                      position: 'absolute',
                      width: '28%',
                      height: '82%',
                      top: '14%',
                      left: '61.8%',
                      opacity: 1,
                      zIndex: 2,
                      transition: '.4s'
                  },
                  {
                      id: 'E',
                      position: 'absolute',
                      width: '22%',
                      height: '72%',
                      top: '19.2%',
                      left: '78%',
                      opacity: 1,
                      zIndex: 1,
                      transition: '.4s'
                  },
                  {
                      id: 'E+',
                      position: 'absolute',
                      width: '22%',
                      height: '72%',
                      top: '19.2%',
                      left: '100%',
                      opacity: 0,
                      zIndex: 0,
                      transition: '.4s'
                  }
              ]
          };
      },
      methods: {
          // 获取数据
          // async getData() {
          //     this.$nextTick(() => {
          //         this.loading = false;
          //     });
          // },
          // 滑动上一个
          prev() {
              // this.imgs.unshift(this.imgs.pop());
              this.config5.push(this.config5.shift());
              this.currentIndex = this.currentIndex - 1;
              if (this.currentIndex < 0) {
                  this.currentIndex = this.imgs.length - 1;
              }
              this.centerCard();
              this.centerIndex('prev');
          },
          // 滑动下一个
          next() {
              // this.imgs.push(this.imgs.shift());
              this.config5.unshift(this.config5.pop());
              this.currentIndex = this.currentIndex + 1;
              if (this.currentIndex > this.imgs.length - 1) {
                  this.currentIndex = 0;
              }
              this.centerCard();
              this.centerIndex('next');
              // console.log(this.currentIndex);
          },
          
          formatSwiper() {
              if (this.startX > this.endX) {
                //   console.log('左边滑动');
                  if (this.startX > this.endX + 40) {
                      this.next();
                  }
              } else {
                //   console.log('右边滑动');
                  if (this.endX > this.startX + 40) {
                      this.prev();
                  }
              }
          },
          // 阻止touchmove的横向默认事件（ios快捷操作会关闭页面）
          stopDefault(event) {
              let differenceY = this.endY - this.startY;
              let differenceX = this.endX - this.startX;
              if (Math.abs(differenceX) > Math.abs(differenceY)) {
                  event.preventDefault();
              }
          },
          // 当前imgs在位置上的index（并非img数组的index）
          centerIndex(val) {
              if (val == 'prev') {
                  for (let val of this.imgs) {
                      if (val.index == this.imgs.length - 1) {
                          val.index = 0;
                      } else {
                          val.index = val.index + 1;
                      }
                  }
              } else {
                  for (let val of this.imgs) {
                      if (val.index == 0) {
                          val.index = this.imgs.length - 1;
                      } else {
                          val.index = val.index - 1;
                      }
                  }
              }
          },
          // 点击
          chooseItem(item) {
              let cycles = item.index;
              if (item.index < 3) {
                  for (let i = 0; i < 3 - cycles; i++) {
                    //   console.log(item.index);
                      this.prev();
                  }
              } else if (item.index > 3) {
                  for (let i = -1; i < item.index - 3; i++) {
                      this.next();
                  }
              } else if (item.index == 3) {
                //   console.log('投票');
              }
          },
          // 计算中间卡片信息
          centerCard() {
              this.centerInfo = this.imgs[this.currentIndex];
              this.$emit('centerInfo', this.centerInfo);
              // this.$emit('centerInfo', this.centerInfo);
              // console.log(this.imgs[2].id);
          },
  
          addCardStyle() {
              if (this.imgs.length > 7) {
                  let addtime = this.imgs.length - 7;
                  for (let i = 0; i < addtime; i++) {
                      console.log('add');
                      this.config5.push({
                          id: 'center',
                          position: 'absolute',
                          width: '45%',
                          height: '100%',
                          top: '0px',
                          left: '50%',
                          marginLeft: '-22.5%',
                          opacity: 0,
                          transition: '.1s'
                      });
                  }
              }
          }
      },
      created() {
          // this.getData();
          this.centerCard(); // 获取中间卡片信息
          this.addCardStyle();// 加入样式位置的index
      }
  };
  </script>
  
  <style lang="scss" scoped>
  .swiper {
      width: 50vw;
      // border: 1px red solid;
      height: 38rem;
      position: relative;
      overflow: hidden;
      div {
          opacity: 0;
      }
  }
  </style>
  
  