<template>
  <div class="main">
    <div class="navBarArea" >
        <nav-bar  :nowSelectedPage=2 :scrolled="pageScrolled" ref="navbar" @tabMenu="subMenuSwitchTab"></nav-bar>
    </div>
    <img v-if="selected == 0" src="../assets/whatWeDoPic1.webp" alt="" class="topBackground">
    <img v-else src="../assets/whatWeDoPic2.webp" alt="" class="topBackground">
    <div class="tabWrap">
            <div class="tab" @click="switchTab(0)">
                <div class="text">Products</div>
                <div v-if="selected == 0" class="selectedDash"></div>
            </div>
            <div class="split"></div>
            <div class="tab" @click="switchTab(1)">
                <div class="text">Service</div>
                <div v-if="selected == 1" class="selectedDash"></div>
            </div>
    </div>
    <div v-if="selected == 0" class="subMenuWrap0">
        <div class="thirdLevelMenuWrap">
            <div :class="thirdLevelMenuSelected == 0 ? 'tab active' : 'tab'" @click="changeThirdLevelMenu(0)">Medicines</div>
            <div :class="thirdLevelMenuSelected == 1 ? 'tab active' : 'tab'" @click="changeThirdLevelMenu(1)">Nutritions</div>
            <div :class="thirdLevelMenuSelected == 2 ? 'tab active' : 'tab'" @click="changeThirdLevelMenu(2)">Healthcare</div>
            <div :class="thirdLevelMenuSelected == 3 ? 'tab active' : 'tab'" @click="changeThirdLevelMenu(3)">APIs</div>
        </div>
        <div v-if="thirdLevelMenuSelected == 0" class="MedicinesWrap">
            <div class="categoryMenuWrap">
                <div class="title">Category</div>
                <div @click="changeSelectedMedicineCategory(0)" :class="selectedMedicineCategory == 0 ? 'item selected' : 'item'">Antiparasitic</div>
                <div @click="changeSelectedMedicineCategory(1)" :class="selectedMedicineCategory == 1 ? 'item selected' : 'item'">Antifungal</div>
                <div @click="changeSelectedMedicineCategory(2)" :class="selectedMedicineCategory == 2 ? 'item selected' : 'item'">Anti-inflammatory</div>
                <div @click="changeSelectedMedicineCategory(3)" :class="selectedMedicineCategory == 3 ? 'item selected' : 'item'">Anti-stress</div>
                <div @click="changeSelectedMedicineCategory(4)" :class="selectedMedicineCategory == 4 ? 'item selected' : 'item'">Behavioural</div>
                <div @click="changeSelectedMedicineCategory(5)" :class="selectedMedicineCategory == 5 ? 'item selected' : 'item'">Cardio Vascular</div>
            </div>
            <product-list :path="medicinesList[selectedMedicineCategory].path" :productList="medicinesList[selectedMedicineCategory].children" :column=3></product-list>
        </div>
        <div v-else-if="thirdLevelMenuSelected == 1" class="NutritionsWrap">
            <product-list :path="nutritions.path" :productList="nutritions.children" :column=4></product-list>
        </div>
        <div v-else-if="thirdLevelMenuSelected == 2" class="HealthcareWrap">
            <product-list :path="healthcare.path" :productList="healthcare.children" :column=4></product-list>
        </div>
        <div v-else-if="thirdLevelMenuSelected == 3" class="APIsWrap">
            <div class="path">What we do > Products > APIs</div>
            <div class="gridArea">
                <div class="title" v-for="item, index in apiGridItems.title" :key="`${item}-${index}`">{{ item }}</div>
                <div class="item" v-for="item, index in apiGridItems.items" :key="`${item}-${index}`">{{ item }}</div>
            </div>
        </div>
    </div>
    <div v-else class="subMenuWrap1">
        <div v-for="item in serviceList" :key="item.id" class="itemWrap" @mouseenter="showMask(item.id)" @mouseleave="hidewMask(item.id)">
            <img :src="item.pic" alt="" class="background">
            <div class="mask" :id="item.id">{{item.text}}</div>
        </div>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import uniqueId from "lodash.uniqueid";
import ProductList from './ProductList.vue';
import PageFooter from './PageFooter.vue';

export default {
    props: {
        attr: {
            type: Object
        }
    },
    data() {
        return {
            thirdLevelMenuSelected: 0,
            selected: this.attr.tabIndex,
            pageScrolled: false,
            selectedMedicineCategory: 0,
            apiGridItems: {
                title:[
                    "Product Name",
                    "Cas No.",
                    "Standard",
                    "Certificate",
                    "Status",
                ],
                items: [
                    "Afoxolaner",
                    "1093861-60-9",
                    "in-house",
                    "/",
                    "In development",
                    "Emodepside",
                    "155030-63-0",
                    "in-house",
                    "DMF",
                    "Commercial",
                    "Eprinomectin",
                    "123997-26-2",
                    "USP",
                    "GMP",
                    "Commercial",
                    "Febantel",
                    "58306-30-2",
                    "EP",
                    "GMP/APVMA approved",
                    "Commercial",
                    "Fluralaner",
                    "864731-61-3",
                    "in-house",
                    "DMF/APVMA under application",
                    "In development",
                    "Grapiprant",
                    "415903-37-6",
                    "Ein-house",
                    "/",
                    "In development",
                    "Imidacloprid",
                    "105827-78-9",
                    "EP",
                    "GMP/APVMA approved",
                    "Commercial",
                    "Lufenuron",
                    "103055-07-8",
                    "EP",
                    "/",
                    "Commercial",
                    "Maropitant Citrate",
                    "359875-09-5",
                    "in-house",
                    "GMP",
                    "Commercial",
                    "Milbemycin Oxime",
                    "129496-10-2",
                    "EP",
                    "GMP",
                    "Commercial",
                    "Moxidectin",
                    "113507-06-5",
                    "EP",
                    "GMP/APVMA approved",
                    "Commercial",
                    "Oclacitinib Maleate",
                    "1208319-27-0",
                    "in-house",
                    "/",
                    "In development",
                    "Pyriproxyfen",
                    "95737-68-1",
                    "in-house",
                    "GMP",
                    "Commercial",
                    "Selamectin",
                    "220119-17-5",
                    "EP",
                    "GMP",
                    "Commercial",
                    "lgY (Yolk immunoglobulin)",
                    "/",
                    "in-house",
                    "/",
                    "Commercial",
                ]
            },
            healthcare: {
                path: "What we do > Products > Healthcare",
                children: [
                    {
                        id: uniqueId("healthcare-"),
                        title: "Anti-allergy Environment Spray",
                        brief: "IgY (Yolk immunoglobulin)",
                        category: "",
                        toPage: "HealthcareDetail0"

                    },
                    {
                        id: uniqueId("healthcare-"),
                        title: "Complex Pheromone Diffuser",
                        brief: "Cat pheromone",
                        category: "",
                        toPage: "HealthcareDetail1"
                    },
                    {
                        id: uniqueId("healthcare-"),
                        title: "Complex Pheromone Solid",
                        brief: "Cat pheromone",
                        category: "",
                        toPage: "HealthcareDetail2"
                    },
                    {
                        id: uniqueId("healthcare-"),
                        title: "Complex Pheromone Spray",
                        brief: "Cat pheromone",
                        category: "",
                        toPage: "HealthcareDetail3"
                    },
                    {
                        id: uniqueId("healthcare-"),
                        title: "Urine Occult Blood Test Particles",
                        brief: "Multiple types of cellulose, pre-gelatinized starch, chromogenic reagent",
                        category: "",
                        toPage: "HealthcareDetail4"
                    },
                ]
            },
            nutritions: {
                path: "What we do > Products > Nutritions",
                children: [
                    {
                        id: uniqueId("Nutrition-"),
                        title: "Anti-allergy Tablets",
                        brief: "Custard powder, Vitamin B1, Vitamin B12",
                        category: "Reduce the release of cat allergens and improve allergy symptoms",
                        toPage: "NutritionDetail0"
                    },
                    {
                        id: uniqueId("Nutrition-"),
                        title: "Anti-Diarrheal Paste",
                        brief: "Elm bark extract, Fructooligosaccharide, mannan oligosaccharide, montmorillonite",
                        category: "Helps regulate intestinal function and protect the gastrointestinal mucosa",
                        toPage: "NutritionDetail1"
                    },
                    {
                        id: uniqueId("Nutrition-"),
                        title: "Joint Support Tablets",
                        brief: "Glucosamine hydrochloride, chondroitin sulfate sodium, dimethyl sulfoxide (MSM)...",
                        category: "Support for pet's joints and cartilage",
                        toPage: "NutritionDetail2"
                    },
                    {
                        id: uniqueId("Nutrition-"),
                        title: "Urinary Support Tablets",
                        brief: "Oligochitosan, cranberry extract, corn silk, green tea crude extract (theanine source), sodium hyaluronate, L-tryptophan, raspberry, calcium bicarbonate",
                        category: "Support pet’s urinary function",
                        toPage: "NutritionDetail3"
                    },
                ]
            },
            medicinesList: [
                {
                    id: uniqueId("medicine-"),
                    name: "Antiparasitic",
                    path: "What we do > Products > Medicines",
                    children: [
                        {
                            id: uniqueId("Antiparasitic-"),
                            title: "Febantel/Pyrantel\nEmbonate/Praziquantel Tablets",
                            brief: "150 mg febantel, 144 mg pyrantel embonate, and 50 mg praziquantel per tablet\n\n525 mg febantel, 504 mg pyrantel embonate, and 175 mg praziquantel per tablet",
                            category: "Antiparasitic",
                            toPage: "MedicineDetail0"
                        },
                        {
                            id: uniqueId("Antiparasitic-"),
                            title: "Fluralaner and Moxidectin\nSpot-on Solution",
                            brief: "280 mg/ml fluralaner and 14 mg/ml moxidectin",
                            category: "Antiparasitic",
                            toPage: "MedicineDetail1"
                        },
                        {
                            id: uniqueId("Antiparasitic-"),
                            title: "Fluralaner Chewable Tablets",
                            brief: "Fluralaner 112.5 mg, 250 mg, 500 mg, 1000 mg, 1400 mg per tablet",
                            category: "Antiparasitic",
                            toPage: "MedicineDetail2"
                        },
                        {
                            id: uniqueId("Antiparasitic-"),
                            title: "Fluralaner Spot-On Solution",
                            brief: "Fluralaner 280 mg/ml",
                            category: "Antiparasitic",
                            toPage: "MedicineDetail3"
                        },
                        {
                            id: uniqueId("Antiparasitic-"),
                            title: "Imidacloprid and Flumethrin Collar",
                            brief: "12.5 g(38cm): 1.25 g imidacloprid and 0.56g flumethrin\n\n45 g(70cm): 4.50 g imidacloprid and 2.03 g flumethrin",
                            category: "Antiparasitic",
                            toPage: "MedicineDetail4"
                        },
                        {
                            id: uniqueId("Antiparasitic-"),
                            title: "Imidacloprid and Moxidectin\nSpot-on Solution",
                            brief: "100 mg/ml imidacloprid and 25 mg/ml moxidectin",
                            category: "Antiparasitic",
                            toPage: "MedicineDetail5"
                        },
                        {
                            id: uniqueId("Antiparasitic-"),
                            title: "Milbemycin Oxime and\nPraziquantel Tablets",
                            brief: "2.5 mg milbemycin oxime and 25 mg praziquantel per tablet\n\n12.5 mg milbemycin oxime and 125 mg praziquantel per tablet",
                            category: "Antiparasitic",
                            toPage: "MedicineDetail6"
                        },
                        {
                            id: uniqueId("Antiparasitic-"),
                            title: "Selamectin Spot-On Solution",
                            brief: "Selamectin 60 mg/ml\n\nSelamectin 120 mg/ml",
                            category: "Antiparasitic",
                            toPage: "MedicineDetail7"
                        },
                        
                    ]
                },
                {
                    id: uniqueId("medicine-"),
                    name: "Antifungal",
                    path: "What we do > Products > Medicines",
                    children: [
                        {
                            id: uniqueId("Antifungal-"),
                            title: "Itraconazole Oral Solution",
                            brief: "Itraconazole 10 mg/ml",
                            category: "Antiparasitic",
                            toPage: "MedicineDetail8"
                        },
                    ]
                },
                {
                    id: uniqueId("medicine-"),
                    name: "Anti-inflammatory",
                    path: "What we do > Products > Medicines",
                    children: [
                        {
                            id: uniqueId("Antifungal-"),
                            title: "Oclacitinib Chewable Tablets",
                            brief: "Oclacitinib (as oclacitinib maleate)3.6 mg, 5.4 mg, 16 mg per chewable tablet",
                            category: "Antiparasitic",
                            toPage: "MedicineDetail9"
                        },
                    ]
                },
                {
                    id: uniqueId("medicine-"),
                    name: "Anti-stress",
                    path: "What we do > Products > Medicines",
                    children: [
                        {
                            id: uniqueId("Antifungal-"),
                            title: "Pregabalin Oral Solution",
                            brief: "Pregabalin 50 mg/ml",
                            category: "Antiparasitic",
                            toPage: "MedicineDetail10"
                        },
                    ]
                },
                {
                    id: uniqueId("medicine-"),
                    name: "Behavioural",
                    path: "What we do > Products > Medicines",
                    children: [
                        {
                            id: uniqueId("Antifungal-"),
                            title: "Mirtazapine Transdermal Ointment",
                            brief: "20 mg of Mirtazapine per 1g",
                            category: "Antiparasitic",
                            toPage: "MedicineDetail11"
                        },
                    ]
                },
                {
                    id: uniqueId("medicineyyy-"),
                    name: "Cardio Vascular",
                    path: "What we do > Products > Medicines",
                    children: [
                        {
                            id: uniqueId("Antifungal-"),
                            title: "Pimobendan Tablets",
                            brief: "Pimobendan 5 mg per tablet",
                            category: "Antiparasitic",
                            toPage: "MedicineDetail12"
                        },
                    ]
                },
            ],
            serviceList: [
                {
                    id: uniqueId("service-"),
                    pic: require("../assets/whatWeDoServicePic/1.webp"),
                    text: "Research, develop, and produce veterinary related medicines, nutritions and healthcare products"
                },
                {
                    id: uniqueId("service-"),
                    pic: require("../assets/whatWeDoServicePic/2.webp"),
                    text: "Regulatory support in China and overseas market"
                },
                {
                    id: uniqueId("service-"),
                    pic: require("../assets/whatWeDoServicePic/3.webp"),
                    text: "Sales and marketing across China"
                },
                {
                    id: uniqueId("service-"),
                    pic: require("../assets/whatWeDoServicePic/4.webp"),
                    text: "Contract research,Contract development and manufacturing"
                },
                {
                    id: uniqueId("service-"),
                    pic: require("../assets/whatWeDoServicePic/5.webp"),
                    text: "In licensing unique products to fast growing Chinese market"
                },
                {
                    id: uniqueId("service-"),
                    pic: require("../assets/whatWeDoServicePic/6.webp"),
                    text: "Out licensing portfolio to overseas market"
                },
                {
                    id: uniqueId("service-"),
                    pic: require("../assets/whatWeDoServicePic/7.webp"),
                    text: "API sourcing, qualification and stable supply"
                },
            ]
        }
    },
    components: {
        NavBar,
        PageFooter,
        ProductList,
    },
    methods: {
        switchTab(index) {
            this.selected = index
            let nowURL = window.location.href
            nowURL = nowURL.slice(0, nowURL.indexOf("#"))
            window.location.assign(nowURL + "#WhatWeDo" + index)
        },
        subMenuSwitchTab(name) {
            if (name == "WhatWeDo0" || name == "WhatWeDo1") {
                this.selected = Number(name.slice(-1))
            }
        },
        showMask(id) {
            window.$(`#${id}`).css("opacity", "100%")
        },
        hidewMask(id) {
            window.$(`#${id}`).css("opacity", "0")
        },
        changeThirdLevelMenu(index) {
            this.thirdLevelMenuSelected = index
        },
        changeSelectedMedicineCategory(index) {
            this.selectedMedicineCategory = index
        },
    },
    mounted() {
        const that = this
        window.addEventListener('scroll', function() {
            // console.log("aaa:" + that.$refs.navbar.isScrolled)
            if (window.$(document).scrollTop() >= 5) {
                that.pageScrolled = true
                // console.log("window.$(document).scrollTop:"+window.$(document).scrollTop())
            }
            else {
                that.pageScrolled = false
            }
        })
    },

}
</script>

<style scoped lang="scss">
.main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.topBackground {
  width: 100%;
  height: auto;
}

.tabWrap {
    width: 100%;
    height: 4rem;
    background-color: rgba($color: #7F7F7F, $alpha: 0.2);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12%;

    .split {
        width: 1px;
        height: 40%;
        background-color: #2C2B2B;
        margin-right: 4rem;
    }

    .tab {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        margin-right: 4rem;
        cursor: pointer;

        .text {
            font-size: 1.2rem;
            font-weight: 500;
            color: #2C2B2B;
        }

        .selectedDash {
            height: 0.4rem;
            width: 130%;
            background-color: $mainColor;
            position: absolute;
            bottom: 0.3rem;
            left: -15%;
            border-radius: 0.3rem;
        }

    }
}

.subMenuWrap0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .thirdLevelMenuWrap {
        margin-top: 4rem;
        width: 100%;
        height: 4rem;
        display: flex;
        flex-direction: row;
        padding: 0 12%;
        align-items: center;
        justify-content: space-between;

        .tab {
            height: 100%;
            width: 24%;
            background-color: rgba($color: $mainColor, $alpha: 0.2);
            text-align: center;
            line-height: 4rem;
            color: #2C2B2B;
            cursor: pointer;
        }

        .active {
            background-color: $mainColor;
            color: #FFFFFF;
        }
    }

    .MedicinesWrap {
        width: 76%;
        background-color: rgba($color: #D8D8D8, $alpha: 0.3);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 3rem 4rem 4rem 4rem;
        margin-top: 3rem;
        margin-bottom: 6rem;

        .categoryMenuWrap {
            display: flex;
            flex-direction: column;
            width: 29%;
            margin-right: 3rem;
            flex-shrink: 1;
            

            .title {
                background-color: $mainColor;
                font-size: 2rem;
                color: #FFFFFF;
                padding: 1.5rem 1.5rem;
            }

            .item {
                padding: 1rem 1.5rem;
                background-color: #FFFFFF;
                color: #7F7F7F;
                cursor: pointer;
            }

            .selected {
                color: $mainColor;
                font-weight: 600;
            }
        }
    }

    .NutritionsWrap {
        width: 76%;
        background-color: rgba($color: #D8D8D8, $alpha: 0.3);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 3rem 4rem 4rem 4rem;
        margin-top: 3rem;
        margin-bottom: 6rem;
    }

    .HealthcareWrap {
        width: 76%;
        background-color: rgba($color: #D8D8D8, $alpha: 0.3);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 3rem 4rem 4rem 4rem;
        margin-top: 3rem;
        margin-bottom: 6rem;
    }

    .APIsWrap {
        width: 76%;
        display: flex;
        flex-direction: column;
        margin-top: 3rem;
        margin-bottom: 7rem;

        .path {
            color: #2C2B2B;
            font-weight: 600;
            margin-bottom: 2rem;
        }

        .gridArea {
            width: 100%;
            display: grid;
            grid-template-columns: 3fr 2fr 3fr 3fr 2fr;
            grid-template-rows: 4rem;
            grid-auto-rows: 4rem;
            justify-items: center;
            align-items: center;

            .title {
                place-self: stretch;
                background-color: $mainColor;
                display: flex; /* 使用 flex 可以让内容居中 */
                justify-content: center;
                align-items: center;
                color: #FFFFFF;
                font-weight: 600;
                outline: 1px solid #D8D8D8;
            }

            .item {
                place-self: stretch;
                background-color: $mainColor;
                display: flex; /* 使用 flex 可以让内容居中 */
                justify-content: center;
                align-items: center;
                color: #646465;
                outline: 1px solid #D8D8D8;
            }

            .item:nth-child(10n+1),
            .item:nth-child(10n+2),
            .item:nth-child(10n+3),
            .item:nth-child(10n+4),
            .item:nth-child(10n+5) {
                background-color: #FFFFFF;
            }

            .item:nth-child(10n+6),
            .item:nth-child(10n+7),
            .item:nth-child(10n+8),
            .item:nth-child(10n+9),
            .item:nth-child(10n+10) {
                background-color: rgba($color: #EEEEEE, $alpha: 0.5);
            }

        }

    }
}

.subMenuWrap1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 3rem;
    margin-bottom: 9rem;
    padding: 0 5%;
    flex-flow: wrap;
    justify-content: center;

    .itemWrap {
        display: flex;
        flex-direction: column;
        width: 22%;
        position: relative;
        flex-shrink: 0;
        margin-top: 4rem;
        cursor: pointer;

        .background {
            width: 100%;
            height: auto;
            font-size: 0.6rem;
        }

        .mask {
            position: absolute;
            z-index: 10;
            background-color: rgba($color: $mainColor, $alpha: 0.7);
            width: 92.3%;
            height: 83.5%;
            opacity: 0;
            left: 2.5%;
            bottom: 13.5%;
            clip-path: polygon(14.5% 0, 100% 0, 85.5% 100%, 0 100%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 12%;
            color: #FFFFFF;
            transition: all 0.3s ease;
        }

        
    }
}



</style>