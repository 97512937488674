<template>
  <component :is="currentView[0]" :attr="currentView[1]" />
</template>

<script>
import Home from './components/Home.vue';
import WhoWeAre from './components/WhoWeAre.vue';
import ContactUs from './components/ContactUs.vue';
import OurActivities from './components/OurActivities.vue';
import WhatWeDo from './components/WhatWeDo.vue';
import MedicineDetail from './components/MedicineDetail.vue';
import NutritionDetail from './components/NutritionDetail.vue';
import HealthcareDetail from './components/HealthcareDetail.vue';






const routes = {
  '/': {
    pageNmae: "Home",
    attr: null
  },
  'WhoWeAre': {
    pageNmae: "WhoWeAre",
    attr: null
  },
  'ContactUs': {
    pageNmae: "ContactUs",
    attr: null
  },
  'OurActivities0': {
    pageNmae: "OurActivities",
    attr: {
      tabIndex: 0
    }
  },
  'OurActivities1': {
    pageNmae: "OurActivities",
    attr: {
      tabIndex: 1
    }
  },
  'OurActivities2': {
    pageNmae: "OurActivities",
    attr: {
      tabIndex: 2
    }
  },
  'WhatWeDo0': {
    pageNmae: "WhatWeDo",
    attr: {
      tabIndex: 0
    }
  },
  'WhatWeDo1': {
    pageNmae: "WhatWeDo",
    attr: {
      tabIndex: 1
    }
  },
  'MedicineDetail0': {
    pageNmae: "MedicineDetail",
    attr: {
      product: 0
    }
  },
  'MedicineDetail1': {
    pageNmae: "MedicineDetail",
    attr: {
      product: 1
    }
  },
  'MedicineDetail2': {
    pageNmae: "MedicineDetail",
    attr: {
      product: 2
    }
  },
  'MedicineDetail3': {
    pageNmae: "MedicineDetail",
    attr: {
      product: 3
    }
  },
  'MedicineDetail4': {
    pageNmae: "MedicineDetail",
    attr: {
      product: 4
    }
  },
  'MedicineDetail5': {
    pageNmae: "MedicineDetail",
    attr: {
      product: 5
    }
  },
  'MedicineDetail6': {
    pageNmae: "MedicineDetail",
    attr: {
      product: 6
    }
  },
  'MedicineDetail7': {
    pageNmae: "MedicineDetail",
    attr: {
      product: 7
    }
  },
  'MedicineDetail8': {
    pageNmae: "MedicineDetail",
    attr: {
      product: 8
    }
  },
  'MedicineDetail9': {
    pageNmae: "MedicineDetail",
    attr: {
      product: 9
    }
  },
  'MedicineDetail10': {
    pageNmae: "MedicineDetail",
    attr: {
      product: 10
    }
  },
  'MedicineDetail11': {
    pageNmae: "MedicineDetail",
    attr: {
      product: 11
    }
  },
  'MedicineDetail12': {
    pageNmae: "MedicineDetail",
    attr: {
      product: 12
    }
  },
  'NutritionDetail0': {
    pageNmae: "NutritionDetail",
    attr: {
      product: 0
    }
  },
  'NutritionDetail1': {
    pageNmae: "NutritionDetail",
    attr: {
      product: 1
    }
  },
  'NutritionDetail2': {
    pageNmae: "NutritionDetail",
    attr: {
      product: 2
    }
  },
  'NutritionDetail3': {
    pageNmae: "NutritionDetail",
    attr: {
      product: 3
    }
  },
  'HealthcareDetail0': {
    pageNmae: "HealthcareDetail",
    attr: {
      product: 0
    }
  },
  'HealthcareDetail1': {
    pageNmae: "HealthcareDetail",
    attr: {
      product: 1
    }
  },
  'HealthcareDetail2': {
    pageNmae: "HealthcareDetail",
    attr: {
      product: 2
    }
  },
  'HealthcareDetail3': {
    pageNmae: "HealthcareDetail",
    attr: {
      product: 3
    }
  },
  'HealthcareDetail4': {
    pageNmae: "HealthcareDetail",
    attr: {
      product: 4
    }
  },
  

}

export default {
  name: 'App',
  components: {
    Home,
    WhoWeAre,
    ContactUs,
    OurActivities,
    WhatWeDo,
    MedicineDetail,
    NutritionDetail,
    HealthcareDetail
  },

  data() {
    return {
      currentPath: window.location.hash
    }
  },
  computed: {
    currentView() {
      var currentRoute = routes[this.currentPath.slice(1) || '/']
      return [currentRoute.pageNmae, currentRoute.attr]
    }
  },
  watch: {
    currentView() {
        document.querySelector('html').scrollTop = 0;
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash
    })
    let windowSize = [window.$(document).width(), window.$(document).height()]
    window.$(":root").css("font-size", `${Math.ceil(windowSize[0] / 1920 * 16)}px`)  // 选择html用$(":root"),用$(html)是无效的
    window.addEventListener('resize', () => {
      let windowSize = [window.$(document).width(), window.$(document).height()]
      window.$(":root").css("font-size", `${Math.ceil(windowSize[0] / 1920 * 16)}px`)  // 选择html用$(":root"),用$(html)是无效的
    })
  }
}
</script>

<style>

html, body, #app {
  width: 100%;
  height: 100%;
  margin: 0;
}
</style>
