import NavBar from './NavBar.vue';
import PageFooter from "./PageFooter.vue";
import { ElCarousel, ElCarouselItem } from 'element-plus';
import 'element-plus/es/components/carousel/style/css'
import 'element-plus/es/components/carousel-item/style/css'

export default {
  props: {
    attr: {}
  },
  components: {
    NavBar,
    ElCarousel,
    ElCarouselItem,
    PageFooter
  },
  data() {
    return {
      picList: [require("../assets/homePic1.webp"), require("../assets/homePic2.webp"), require("../assets/homePic3.webp")],
      screenSize: [0, 0],
      swiperHeight: "100vh",
      selectedBriefIndex: 0,
      briefList: ["We are dedicated to improving animal healthcare and providing premium products that enhance the well-being of animals everywhere. ", "Our passion for animals drives us to deliver innovative solutions globally, ensuring that all animals receive the care they deserve, no matter where they are. ", "We envision a future where every animal has access to the highest quality products, ensuring a healthier, happier life for all species. "]
    };
  },
  computed: {},
  mounted() {
    // const that = this
    window.addEventListener("resize", function () {
      this.screenSize = [document.body.clientWidth, document.body.clientHeight];
      // console.log(this.screenSize)
    });
  },
  methods: {
    changePic(next) {
      // console.log([prev, next])

      window.$(".textArea").css("opacity", "0");
      this.selectedBriefIndex = next;
      setTimeout(function () {
        this.$(".textArea").css("opacity", "1");
      }, 200);
    }
    // setMask() {
    //     window.$(".mask").css("height", "100%")
    // },
    // setMaskDown() {
    //     window.$(".mask").css("height", "0")
    // }
  }
};