<template>
    <div class="main">
      <div class="navBarArea" >
          <nav-bar  :nowSelectedPage=2 :scrolled="pageScrolled" ref="navbar" @tabMenu="subMenuSwitchTab"></nav-bar>
      </div>
      <img src="../assets/productDetailPic1.webp" alt="" class="topBackground">
      <div class="headWrap">
          <div class="leftWrap">
              <div class="text">PRODUCTS</div>
              <div class="dash"></div>
          </div>
          <div class="pathWrap">
              <div class="path1">{{ this. healthcareList[selected].path1 }}</div>
              <div class="path2">{{ this. healthcareList[selected].path2 }}</div>
          </div>
      </div>
      <div class="detailWrap">
          <div class="title">{{ this. healthcareList[selected].title }}</div>
          <div class="mainSubject">About This Product</div>
          <div class="subject">Details</div>
          <div v-if="healthcareList[selected].detailList.length != 0" style="display: flex; flex-direction: column; margin-bottom: 2rem;in">
              <div v-for="item, index in  healthcareList[selected].detailList" :key="index" class="unorderedList">
                <div class="dot"></div>
                <div class="text">{{ item }}</div>
              </div>
          </div>
          <div v-if="this. healthcareList[selected].detailMore != ''" class="content">{{ this. healthcareList[selected].detailMore }}</div>
          <div class="subject">Specifications</div>
          <img v-if="this. healthcareList[selected].specification != ''" :src="this. healthcareList[selected].specification" :style="`width: ${this. healthcareList[selected].specificationWidth}; height: auto; margin-top: 1rem; margin-bottom: 1rem;`" alt="">
          <div class="subject">Precautions</div>
          <div class="content">{{ this. healthcareList[selected].precaution }}</div>
          <div class="mainSubject">Ingredient information</div>
          <div class="subject">Ingredients</div>
          <div class="content">{{ this. healthcareList[selected].ingredient }}</div>
          <div v-if="this. healthcareList[selected].analysis != ''" class="subject">Guaranteed Analysis (per tablet)</div>
          <img v-if="this. healthcareList[selected].analysis != ''" :src="this. healthcareList[selected].analysis" :style="`width: ${this. healthcareList[selected].analysisWidth}; height: auto; margin-top: 1rem; margin-bottom: 1rem;`" alt="">
          <div class="subject">Instructions</div>
          <div class="content">{{ this. healthcareList[selected].instruction }}</div>
          <img v-if="this. healthcareList[selected].instructionGrid != ''" :src="this. healthcareList[selected].instructionGrid" :style="`width: ${this. healthcareList[selected].instructionGridWidth}; height: auto; margin-top: 1rem; margin-bottom: 1rem;`" alt="">
          <div class="buttonWrap" @click="goBack">
              <img src="../assets/productdetailIcon1.webp" alt="" class="icon">
              <div class="text">BACK TO OVERVIEW</div>
          </div>
      </div>
      <page-footer></page-footer>
    </div>
  </template>
  
  <script>
  import NavBar from './NavBar.vue';
  import PageFooter from './PageFooter.vue';
  
  export default {
      props: {
          attr: {
              type: Object
          }
      },
      components: {
          NavBar,
          PageFooter
      },
      data() {
          return {
              pageScrolled: false,
              selected: this.attr.product,
              healthcareList: [
                  {
                      id:0,
                      path1: "what we do > Products > Healthcare >",
                      path2: "Anti-allergy Environment Spray",
                      title: "Anti-allergy Environment Spray",
                      detailList: [
                        "Anti-allergy Environment Spray reduces environmental cat allergens and improves allergy symptoms.",
                        "Contains lgY (Yolk immunoglobulin), a biologically active antibody derived from egg yolk, to rapidly deactivate the cat allergens Fel d1 and Fel d4 in the environment.",
                        "Improves cat-induced sneezing, runny or stuffy nose, itchy, red or watery eyes, coughing or wheezing, rashes, redness or itching of the skin, etc.",
                      ],
                      detailMore: "More than 90% of cat allergy people have IgE reactions to Fel d1 and Fel d4, the allergens secreted by the salivary and sebaceous glands of cats, resulting in allergy. Anti-allergy Environment Spray double reduce the cat allergens Fel d1 and Fel d4 in the environment, improve the symptoms of cat hair allergy in people, and realize the freedom of sucking cats.",
                      specification: require("../assets/healthcare/specification/0.webp"),
                      specificationWidth: "30%",
                      precaution: "Keep out of reach of children. Avoid spraying in eyes.",
                      ingredient: "IgY (Yolk immunoglobulin)",
                      analysis: "",
                      analysisWidth: "30%",
                      instruction: "Store in a cool, dry area out of direct sunlight.\nSpray areas and items that have allergens attached to them (e.g., litter pads, cages, sofas, mattresses, litter boxes, and pet hair) 1-3 times per day.",
                      instructionGrid: "",
                      instructionGridWidth: "32%",
                  },
                  {
                      id:1,
                      path1: "what we do > Products > Healthcare >",
                      path2: "Complex Pheromone Diffuser",
                      title: "Complex Pheromone Diffuser",
                      detailList: [
                        "Powered by pheromones, it mimics the pheromone that cats use to mark places, objects, and persons as familiar.",
                        "Create a more comfortable environment for your cat and help reduce scratching, urine marking, and excessive meowing caused by stress and fear.",
                        "Relax your cat quickly without sedation, at home or on the go.",
                        "Ideal for vet visits, traveling, and introductions to new pets or people.",
                      ],
                      detailMore: "Complex Pheromone Diffuser releases feel-good signals that mimic the F3&F4 feline facial pheromone and feline appeasing pheromone—a pheromone that cats use to mark places, objects, and people as familiar. As Complex Pheromone Diffuser sends these kitty calming signals into the environment, it helps boost your cat's mood and helps reduce stress-induced behaviors. When your cat senses the signals, it can help her feel calmer, safer, and more secure at home or on the go.",
                      specification: require("../assets/healthcare/specification/1.webp"),
                      specificationWidth: "30%",
                      precaution: "Keep out of reach of children. Warning: Flammable Eye Irritant. May cause drowsiness or dizziness. If ingested, do not induce vomiting. Call a physician if you feel unwell and seek medical advice.",
                      ingredient: "Cat pheromone",
                      analysis: "",
                      analysisWidth: "30%",
                      instruction: "Store in a well-ventilated, cool place.\n\nAttach the supplement to an electrically heated diffuser and plug it into your cat's new environmental area outlet and press the lighted switch to release the pheromones two hours in advance. Please use consistently and replace the refill replacement every 30 days and the heater every 2-3 months.",
                      instructionGrid: "",
                      instructionGridWidth: "32%",
                  },
                  {
                      id:2,
                      path1: "what we do > Products > Healthcare >",
                      path2: "Complex Pheromone Solid",
                      title: "Complex Pheromone Solid",
                      detailList: [
                        "Powered by pheromones, it mimics the pheromone that cats use to mark places, objects, and persons as familiar.",
                        "Create a more comfortable environment for your cat and help reduce scratching, urine marking, and excessive meowing caused by stress and fear.",
                        "Relax your cat quickly without sedation, at home or on the go.",
                        "Ideal for vet visits, traveling, and introductions to new pets or people.",
                      ],
                      detailMore: "Complex pheromone solid releases feel-good signals that mimic the F3 feline facial pheromone—a pheromone that cats use to mark places, objects, and people as familiar. As Complex pheromone solid sends these kitty calming signals into the environment, it helps boost your cat's mood and helps reduce stress-induced behaviors. When your cat senses the signals, it can help her feel calmer, safer, and more secure at home or on the go.",
                      specification: require("../assets/healthcare/specification/2.webp"),
                      specificationWidth: "30%",
                      precaution: "Keep out of reach of children. Warning: Flammable Eye Irritant. May cause drowsiness or dizziness. If ingested, do not induce vomiting. Call a physician if you feel unwell and seek medical advice.",
                      ingredient: "Cat pheromone.",
                      analysis: "",
                      analysisWidth: "30%",
                      instruction: "Store in a well-ventilated, cool place.\n\nBegin application 15 minutes before the cat enters a stressful situation such as a cat bag, vet visits, traveling or a new cat arrives home.",
                      instructionGrid: "",
                      instructionGridWidth: "32%",
                  },
                  {
                      id:3,
                      path1: "what we do > Products > Healthcare >",
                      path2: "Complex Pheromone Spray",
                      title: "Complex Pheromone Spray",
                      detailList: [
                        "Powered by pheromones, it mimics the pheromone that cats use to mark places, objects, and persons as familiar.",
                        "Create a more comfortable environment for your cat and help reduce scratching, urine marking, and excessive meowing caused by stress and fear.",
                        "Relax your cat quickly without sedation, at home or on the go.",
                        "Ideal for vet visits, traveling, and introductions to new pets or people.",
                      ],
                      detailMore: "Complex Pheromone Spray releases feel-good signals that mimic the F3 feline facial pheromone—a pheromone that cats use to mark places, objects, and people as familiar. As Complex Pheromone Spray sends these kitty calming signals into the environment, it helps boost your cat's mood and helps reduce stress-induced behaviors. When your cat senses the signals, it can help her feel calmer, safer, and more secure at home or on the go.",
                      specification: require("../assets/healthcare/specification/3.webp"),
                      specificationWidth: "30%",
                      precaution: "Do not spray directly on cat. Keep out of reach of children. Warning: Flammable Eye Irritant. May cause drowsiness or dizziness. Highly flammable liquid and vapor. If ingested, do not induce vomiting. Call a physician if you feel unwell and seek medical advise.",
                      ingredient: "Cat pheromone.",
                      analysis: "",
                      analysisWidth: "30%",
                      instruction: "Store in a well ventilated, cool place.\n\nBegin application 15 minutes before tne cat enters a stressful situation such as a cat bag, vet visits, traveling or a new cat arrives home; press and spray 8 times on areas touched by cats. Repeat application every 0.5-1 hour if necessary.",
                      instructionGrid: "",
                      instructionGridWidth: "32%",
                  },
                  {
                      id:4,
                      path1: "what we do > Products > Healthcare >",
                      path2: "Urine Occult Blood Test Particles",
                      title: "Urine Occult Blood Test Particles",
                      detailList: [],
                      detailMore: "Urinary Occult Blood Test Particles can be used to detect cat urine blood and urine occult blood for early detection of urinary problems and for review and follow-up during recovery from urinary tract disorders. Cat urine occult blood is closely related to over 30 diseases that affect their health. Early detection enables timely intervention, treatment and a better prognosis. When the particles contact the hemoglobin in cat urine, the color will turn blue from white within 3 seconds, and the color can be maintained for about 7 days. At this time, we need to pay attention to the urinary health of cats in time.",
                      specification: require("../assets/healthcare/specification/4.webp"),
                      specificationWidth: "38%",
                      precaution: "The particles will not show color when fully immersed in urine. Avoid contact with strong acids, bases and oxidizing agents. Valid for 7 days after opening. Keep out of reach of children.",
                      ingredient: "Multiple types of cellulose, pre-gelatinized starch, chromogenic reagent.",
                      analysis: "",
                      analysisWidth: "30%",
                      instruction: "Store in a ventilated and dry place at room temperature, away from light.\n1、Spread the particles evenly on the cat litter surface.\n2、Check the litter box after your cat urinates.\n3、Observe particles color. Consult a veterinarian if blue appears.",
                      instructionGrid: "",
                      instructionGridWidth: "32%",
                  },
                  
              ]
          }
      },
      methods: {
          goBack() {
            let nowURL = window.location.href
            nowURL = nowURL.slice(0, nowURL.indexOf("#"))
            window.location.assign(nowURL + "#WhatWeDo0")
          }
      },
      mounted() {
          const that = this
          window.addEventListener('scroll', function() {
              // console.log("aaa:" + that.$refs.navbar.isScrolled)
              if (window.$(document).scrollTop() >= 5) {
                  that.pageScrolled = true
                  // console.log("window.$(document).scrollTop:"+window.$(document).scrollTop())
              }
              else {
                  that.pageScrolled = false
              }
          })
      },
  
  }
  </script>
  
  <style scoped lang="scss">
  .main {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
  }
  
  .topBackground {
    width: 100%;
    height: auto;
  }
  
  .headWrap {
      margin-top: 3rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 76%;
      border-bottom: 1px #D8D8D8 solid;
      padding-bottom: 0.4rem;
  
      .leftWrap {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          .text {
              color: #2C2B2B;
              font-size: 1.3rem;
              font-weight: 600;
          }
  
          .dash {
              width: 100%;
              background-color: $mainColor;
              height: 0.2rem;
              margin-top: 0.4rem;
          }
      }
  
      .pathWrap {
          display: flex;
          flex-direction: row;
  
          .path1 {
              color: #7F7F7F;
          }
  
          .path2 {
              margin-left: 0.3rem;
              color: $mainColor;
          }
      }
  }
  
  .detailWrap {
      margin-top: 3rem;
      width: 76%;
      display: flex;
      flex-direction: column;

      .unorderedList {
        display: flex;
        flex-direction: row;
        // align-items: center;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;

        .dot {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: $mainColor;
            margin-right: 1rem;
            margin-top: 0.5rem;
            flex-shrink: 0;
        }

        .text {
            color: #646465;
            white-space: pre-line;
            font-weight: 400;
            line-height: 1.8rem;
        }
      }
  
      .title {
          font-size: 2rem;
          color: $mainColor;
          font-weight: 600;
        //   margin-bottom: 2rem;
      }

      .mainSubject {
        font-size: 1.6rem;
        font-weight: 600;
        color: #1A1A1A;
        margin: 3.3rem 0 0 0;
      }
  
      .subject {
          font-size: 1.1rem;
          font-weight: 600;
          color: #1A1A1A;
          margin: 1.3rem 0 0.5rem 0;
      }
  
      .content {
          color: #646465;
          white-space: pre-line;
          line-height: 1.8rem;
          font-weight: 400;
      }
  
      .buttonWrap {
          width: 100%;
          height: 4rem;
          background-color: #EEEEEE;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 4rem;
          margin-bottom: 7rem;
          cursor: pointer;
  
          .icon {
              width: 0.6rem;
              margin-right: 3rem;
          }
  
          .text {
              font-size: 1.1rem;
              color: #2C2B2B;
          }
      }
  }
  
  </style>